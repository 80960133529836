<template>
  <div class="goods-comment">
    <!-- 头部 -->
    <van-nav-bar
      title="商品评价"
      left-arrow
      @click-left="goBack"
      fixed
      class="dark_bg"
    />
    <!--  -->
    <transition name="fade">
      <preLoading v-if="isLoadings"></preLoading>
    </transition>
    <van-tabs type="card" @change="onChange" class="dark_bg">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$globalConfig.finishedText"
        @load="onLoad"
        :error.sync="error"
        offset:50
        :immediate-check="checkis"
      >
        <van-tab
          :title="item.title"
          v-for="item in commentList.arrlist"
          :key="item.title"
          style="flex-basis: 0"
        >
          <ul class="user-comment">
            <li
              v-for="comment in item.son"
              :key="comment.id"
              class="dark_shop_bg"
              style="margin-left：2rem"
            >
              <p class="commentuser-info">
                <!-- <span class="iconfont icon-yonghutouxiang user-img"></span> -->
                <img
                  src="../../../assets/images/qq.png"
                  alt="用户头像"
                  class="user-img"
                />
                <span>
                  <i class="dark_text">{{ comment.content }}</i>
                  <i>{{ comment.gmtCreate }}</i>
                </span>
              </p>
              <p class="comment-content dark_order">
                {{ comment.score }}
              </p>
              <!-- 回复列表 -->
              <div class="reply">
                <div
                  class="reply-list"
                  v-for="reply in comment.listReplyVO"
                  :key="reply.id"
                >
                  <p class="reply-info">
                    <span class="iconfont icon-kefu reply-img"></span>
                    <span>
                      <i>{{ reply.fromCustomerName }}</i>
                      <i>{{ reply.replyTime}}</i>
                    </span>
                  </p>
                  <p class="reply-content">{{ reply.replyContent }}</p>
                </div>
              </div>

              <!-- 评论图片 -->
              <!-- <p class="comment-imgList">
                <span
                  v-for="(img,index) in comment.commentImg"
                  :key="index"
                  class="comment-img"
                  @click="imagePre(comment.commentImg,index)"
                >
                  <img :src="img" alt />
                </span>
              </p>-->
            </li>
          </ul>
        </van-tab>
        <!-- <p slot="loading">
          <img
            src="../../../assets/images/index.messenger-typing-preloader.svg"
            alt
            style="width: 20%"
          />
        </p> -->
        <loading-infinity slot="loading"></loading-infinity>
        <!-- <p @click="ImagePre">预览图片</p> -->
      </van-list>
    </van-tabs>
  </div>
</template>

<script>
import { NavBar, Tab, Tabs, List, ImagePreview } from "vant";
export default {
  data() {
    return {
      loading: false,
      finished: false,
      error: false,
      currentPage: 1,
      totalPage: Number,
      status: "", // 评价分类状态
      isLoadings: true,
      checkis: false,
      commentList: [
        // {
        //   commentTip: "全部",
        //   commentCon: [
        //     {
        //       user: "喵酱的种草笔记",
        //       content:
        //         "夏天到了，防晒的必备都做好了吗？囤货要开始囤起来啦，当然防晒是一年四季的事情，因为紫外线不管是冬天还是夏天都是有的，隔离紫外线",
        //       time: "5月6日  6：48",
        //       commentImg: [
        //         "https://img4.mukewang.com/szimg/5d5377660922e54c12000676-240-180.png",
        //         "https://img30.360buyimg.com/shaidan/s128x96_jfs/t1/66110/10/7960/94169/5d5e21feE3e62e3c9/4939365872dca9ef.jpg!cc_100x100!q70.dpg.webp",
        //         "https://img30.360buyimg.com/shaidan/s128x96_jfs/t1/34295/19/4561/282913/5cbc4278Eb81bf24c/8ee40a9a05b813fa.jpg!cc_100x100!q70.dpg.webp",
        //         "https://img30.360buyimg.com/shaidan/s128x96_jfs/t1/58596/36/7882/243168/5d58092aE63164639/08817d89231ff666.jpg!cc_100x100!q70.dpg.webp",
        //         "https://img30.360buyimg.com/shaidan/s128x96_jfs/t1/34647/16/5405/87325/5cbf1824Ec46d25be/aab0974efc016570.jpg!cc_100x100!q70.dpg.webp",
        //       ],
        //     },
        //     {
        //       user: "wnagyan222",
        //       content: "这是一个好东西",
        //       time: "5月6日  6：48",
        //     },
        //     {
        //       user: "wnagyan333",
        //       content: "这是一个好东西",
        //       time: "5月6日  6：48",
        //     },
        //   ],
        // },
        // {
        //   commentTip: "最新",
        //   commentCon: [
        //     {
        //       user: "wnagyan111",
        //       content: "最新最新",
        //       time: "5月6日  6：48",
        //     },
        //     {
        //       user: "wnagyan222",
        //       content: "最新最新",
        //       time: "5月6日  6：48",
        //     },
        //     {
        //       user: "wnagyan333",
        //       content: "最新最新",
        //       time: "5月6日  6：48",
        //     },
        //   ],
        // },
        // {
        //   commentTip: "图片(7)",
        //   commentCon: [
        //     {
        //       user: "wnagyan111",
        //       content: "这是一个好东西图片",
        //       time: "5月6日  6：48",
        //     },
        //     {
        //       user: "wnagyan222",
        //       content: "这是一个好东西图片",
        //       time: "5月6日  6：48",
        //     },
        //     {
        //       user: "wnagyan333",
        //       content: "这是一个好东西图片",
        //       time: "5月6日  6：48",
        //     },
        //   ],
        // },
        // {
        //   commentTip: "推荐(8)",
        //   commentCon: [
        //     {
        //       user: "wnagyan111",
        //       content: "这是一个好东西推荐",
        //       time: "5月6日  6：48",
        //     },
        //     {
        //       user: "wnagyan222",
        //       content: "这是一个好东西推荐",
        //       time: "5月6日  6：48",
        //     },
        //     {
        //       user: "wnagyan333",
        //       content: "这是一个好东西推荐",
        //       time: "5月6日  6：48",
        //     },
        //   ],
        // },
        // {
        //   commentTip: "最讨厌的",
        //   commentCon: [
        //     {
        //       user: "wnagyan111",
        //       content: "最新最新",
        //       time: "5月6日  6：48",
        //     },
        //     {
        //       user: "wnagyan222",
        //       content: "最新最新",
        //       time: "5月6日  6：48",
        //     },
        //     {
        //       user: "wnagyan333",
        //       content: "最新最新",
        //       time: "5月6日  6：48",
        //     },
        //   ],
        // },
        // {
        //   commentTip: "一般般(7)",
        //   commentCon: [
        //     {
        //       user: "wnagyan111",
        //       content: "这是一个好东西图片",
        //       time: "5月6日  6：48",
        //     },
        //     {
        //       user: "wnagyan222",
        //       content: "这是一个好东西图片",
        //       time: "5月6日  6：48",
        //     },
        //     {
        //       user: "wnagyan333",
        //       content: "这是一个好东西图片",
        //       time: "5月6日  6：48",
        //     },
        //   ],
        // },
        // {
        //   commentTip: "不满意(8)",
        //   commentCon: [
        //     {
        //       user: "wnagyan111",
        //       content: "这是一个好东西推荐",
        //       time: "5月6日  6：48",
        //     },
        //     {
        //       user: "wnagyan222",
        //       content: "这是一个好东西推荐",
        //       time: "5月6日  6：48",
        //     },
        //     {
        //       user: "wnagyan333",
        //       content: "这是一个好东西推荐",
        //       time: "5月6日  6：48",
        //     },
        //   ],
        // },
      ],
    };
  },
  components: {
    [NavBar.name]: NavBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [List.name]: List,
  },
  created() {
    this.initData(0);
  },
  methods: {
    // 初始化评价列表
    initData(index) {
      this.loading = true;
      this.$api.home
        .getSpuAllAppraise({
          pageNo: 1,
          pageSize: 10,
          accessToken: localStorage.getItem("token"),
          spuId: this.$route.params.goodsId,
        })
        .then((res) => {
          if (res.errno == 200) {
            // this.commentList.title = arr;
            this.commentList.arrlist = [
              {
                title: "全部",
                status: "all",
                son: [],
              },
              {
                title: "满意",
                status: 5,
                son: [],
              },
              {
                title: "基本满意",
                status: 4,
                son: [],
              },
              {
                title: "一般",
                status: 3,
                son: [],
              },
              {
                title: "较差",
                status: 2,
                son: [],
              },
              {
                title: "差评",
                status: 1,
                son: [],
              },
            ];

            this.commentList.arrlist.forEach((v, i) => {
              res.data.items.forEach((j) => {
                if (j.score == v.status) {
                  j.gmtCreate = this.parseTime(j.gmtCreate)
                  j.gmtUpdate = this.parseTime(j.gmtUpdate)

                  // console.log(j);
                  v.son.push(j);
                }
                if (v.status == "all") {
                  j.gmtCreate = this.parseTime(j.gmtCreate)
                  j.gmtUpdate = this.parseTime(j.gmtUpdate)
                  v.son.push(j);
                }
              });
            });
            this.status = this.commentList.arrlist[index].score;
            this.currentPage = res.data.pageNo;
            this.totalPage = res.data.totalPageNo;
            // console.log(this.commentList, "评论列表");

            this.isLoadings = false;
            this.loading = false;
          }
        });
    },
    // 切换分类按钮
    onChange(index, title) {
      this.initData(index);
      this.currentPage = 1;
      this.totalPage = Number;
      this.finished = false;
    },

    parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
},
    // 分页拼接数据
    cancatCommentList() {
      this.currentPage++;
      if (this.currentPage > this.totalPage) {
        this.loading = false;
        return (this.finished = true);
      }
      this.$api.home
        .getSpuAllAppraise({
          pageNo: this.currentPage,
          pageSize: 10,
          accessToken: localStorage.getItem("token"),
          spuId: this.$route.params.goodsId,
        })
        .then((res) => {
          if (res.errno == 200) {
            // console.log(res.data.items, "列表");
            this.commentList.arrlist.forEach((v, i) => {
              res.data.items.forEach((j) => {
                if (j.score == v.status) {
                  v.son.push(j);
                }
                if (v.status == "all") {
                  v.son.push(j);
                }
              });
            });
            this.loading = false;
          } else {
            this.error = true;
          }
        });
    },
    goBack() {
      this.$router.back(0);
    },
    onLoad() {
      // 异步获取数据
      setTimeout(() => {
        this.cancatCommentList();
      }, 1000);
    },
    imagePre(images, index) {
      ImagePreview({
        images,
        startPosition: index,
      });
    },
    getDetail() {
      this.accessToken = localStorage.getItem("token");
      let companyId = this.$route.query.companyId;
      let requestAddress =
        this.$route.query.status == -1
          ? "loadGoodsHomeVOBoomAddress"
          : "goodsDetailAddress";
      if (this.$route.query.companyId == "undefined") companyId = "";
      this.$server[requestAddress]({
        accessToken: this.accessToken,
        spuId: this.$route.params.goodsId,
        companyId: companyId,
      }).then((res) => {
        if (res.data.stock == 0) {
          this.$toast("该商品暂无库存");
          setTimeout(() => {
            this.$router.back();
          }, 500);

          return;
        }
        if (res.errno === 200) {
          this.goods = res.data;
          this.swipeLength = this.goods.imgList.length;
          this.isLoading = false;
          // 发送给组件数据
          this.$root.eventHub.$emit("sendGoods", res.data);
          // 初始化视频
          this.initVideo();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.goods-comment {
  width: 100%;
  box-sizing: border-box;
  padding: 0.96rem /* 48/50 */ 0 0;
  .van-nav-bar {
    box-shadow: 0px 2px 4px #efefef;
    .van-icon {
      color: #333;
    }
  }
  .van-tabs--card {
    padding: 0;
  }
  .van-tabs {
    position: static;
    & /deep/ .van-tabs__wrap {
      position: static;
      height: 100%;
      box-sizing: border-box;
      margin: 0.24rem /* 12/50 */ 0;
      .van-tabs__nav {
        flex-wrap: wrap;
        overflow: auto;
      }
    }

    & /deep/ .van-tabs__nav--card {
      height: 100%;
      margin: 0;
      border: none;
      box-sizing: border-box;
      padding: 0.32rem /* 16/50 */;
      .van-tab {
        border: 1px solid #adadad;
        color: #adadad;
        border-radius: 0.32rem /* 16/50 */;
        margin: 0rem 0.16rem /* 8/50 */ 0.16rem /* 8/50 */ 0;
        flex-basis: auto !important;
        padding: 0rem 0.32rem /* 16/50 */;
        flex: none;
        &.van-tab--active {
          background: #f9ebeb;
          color: #c83f3f;
          border-color: #c83f3f;
        }
      }
    }
  }
  // 评论列表
  .user-comment {
    li {
      width: 96vw;
      box-sizing: border-box;
      background-color: #fff;
      margin: 0 2vw 0.16rem /* 8/50 */;
      padding: 0.32rem /* 16/50 */;
      font-size: 0.32rem /* 16/50 */;
      border-radius: 0.3rem;
      .commentuser-info {
        display: flex;
        align-items: center;
        .user-img {
          width: 0.76rem /* 38/50 */;
          margin-right: 0.18rem /* 9/50 */;
        }
        span {
          display: block;
          font-size: 0.28rem /* 14/50 */;
          i {
            display: block;
            line-height: 0.4rem /* 20/50 */;
            &:first-child {
              color: black;
              font-weight: bold;
            }
            &:last-child {
              font-size: 0.24rem /* 12/50 */;
              color: #666;
            }
          }
        }
      }
      .comment-content {
        line-height: 0.38rem /* 19/50 */;
        font-size: 0.28rem /* 14/50 */;
        margin: 0.16rem /* 8/50 */ 0;
      }
      // 回复列表
      .reply {
        margin: 0.24rem /* 12/50 */ 0 0;
        .reply-list {
          width: 100%;
          box-sizing: border-box;
          padding: 0.16rem /* 8/50 */ 0.32rem /* 16/50 */;
          margin: 0 0 0.18rem /* 9/50 */;
          border-radius: 0.12rem /* 6/50 */;
          background-color: #f5f5f5;
          .reply-info {
            display: flex;
            align-items: center;
            span {
              &.reply-img {
                font-size: 0.56rem /* 28/50 */;
                margin-right: 0.18rem /* 9/50 */;
              }
              display: block;
              i {
                display: block;
                line-height: 0.32rem /* 16/50 */;
                font-size: 0.24rem /* 12/50 */;
                &:first-child {
                  color: black;
                  font-weight: bold;
                }
                &:last-child {
                  color: #948e8e;
                }
              }
            }
          }
          .reply-content {
            line-height: 0.18rem /* 9/50 */;
            color: #948e8e;
            padding-left: 0.64rem /* 32/50 */;
            font-size: 0.24rem /* 12/50 */;
            margin: 0.16rem /* 8/50 */ 0 0;
          }
        }
      }
      // .comment-imgList {
      //   width: 100%;
      //   box-sizing: border-box;
      //   margin: 0.6rem 0 0;
      //   display: flex;
      //   flex-wrap: wrap;
      //   justify-content: space-between;
      //   .comment-img {
      //     width: 32%;
      //     height: 100px;
      //     display: inline-block;
      //     text-align: center;
      //     overflow: hidden;
      //     margin-bottom: 0.4rem;
      //     img {
      //       width: 100%;
      //       height: 100%;
      //     }
      //   }
      // }
    }
  }
}
</style>
